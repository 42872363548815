import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18next from 'i18next'
import { connectionStatus, getKeysProfiles, generateNewKeys, getADUserInfo } from '../actions/api';
import { setKeysProfiles, setProfile, setErrorData, setShowErrorPopup } from '../actions/localStates';

import arrows from '../img/update-arrows.jpeg'

const possibleCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
const captchaLength = 5;

class KeyGenerator extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	    	profileId: "",
	    	newKeyType: "SIGNATURE_AND_AGREEMENT",
	    	selectedProfileId: null,
	    	fields: null,
	    	imgSrc: null,
	    	pattern: "",
	    	capthcaError: "",
	    	captchaValue: "",
	    	captchaIsValid: false,
	    	fieldsIsValid: false,
	    	showLoginForm: false,
	    	showPassword: true,
	    	loginInput: "",
	    	pwdInput: "",
	    	adData: null,
	    	unzrSetted: false,
	    	showNotRequiredFields: false,
	    	adDataRecived: true,
	    	b2DataRecived: true,
	    	showADRecivedDataStatus: false,
	    	showB2RecivedDataStatus: false,
	    	selectedCode: "",
	    	operatorsCodes: ["050", "066", "095", "099", "067", "068", "096", "097", "098", "063", "073", "093"]
	    }

	    this.onDropdownSelected = this.onDropdownSelected.bind(this)
	    this.setCaptchaImg = this.setCaptchaImg.bind(this)
	    this.validate = this.validate.bind(this)
	    this.validateCaptcha = this.validateCaptcha.bind(this)
	    this.clearFields = this.clearFields.bind(this)
	    this.generateJsonToSend = this.generateJsonToSend.bind(this)
	    this.handlePasswordChange = this.handlePasswordChange.bind(this)
	    this.handleLoginChange = this.handleLoginChange.bind(this)
	    this.handleCodeChange = this.handleCodeChange.bind(this)

	    this.patternEDRPOU = /^[0-9]{8}$/;
		this.patternDRFOU = /^[ІіА-яA-z0-9]{10}$/;
		this.patternUNZR = /^[0-9]{8}-[0-9]{5}$/;
		this.patternTELEPHONE_NUMBER = /^[0-9]{3}-[0-9]{2}-[0-9]{2}$/;
	}

	handlePasswordChange(e) {
		this.setState({"pwdInput": e.target.value})
	}

	handleLoginChange(e) {
		this.setState({"loginInput": e.target.value})
	}

	handleCodeChange(e) {
		this.setState({"selectedCode": e.target.value})
	}

	setShowNotRequiredFields() {
		this.setState({"showNotRequiredFields": !this.state.showNotRequiredFields})
	    document.activeElement.blur()
	}

	getADData() {
		this.setState({"adData": null})
		this.props.actions.getADUserInfo(this.props.baseUrls.handymanUrl, this.state.loginInput, this.state.pwdInput)
		.then((response) => {
			console.log(response);
			if (response !== null) {
				this.setState({"adData": response})
			} else {
				this.setState({"adDataRecived": false})
			}
			this.setState({"showADRecivedDataStatus": true})
		})
		.then(() => {
			this.insertADData();
		})
	}

	generateRandomText(length) {
        var resultText = "";
        for (var i = 0; i < length; i++) {
            resultText += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
        }
        return resultText;
    };

	componentDidMount() {
		var _this = this
		this.props.actions.getKeysProfiles(this.props.connectionStatus.baseUrl)
		.then((response) => {
			this.props.actions.setKeysProfiles(response)
			console.log(response);
			this.setState({fields: null})
			this.setState({"showLoginForm": false})
			this.props.actions.setProfile(0)

			this.props.baseUrls.keysProfilesIdsForAD.forEach(function (index) {
				if (_this.props.keyGeneratorReducer.keysProfiles[0].id === index) {
					_this.setState({"showLoginForm": true})
				}
			})

			this.setState({selectedProfileId: 0}, () => {
				this.setState({fields: this.props.keyGeneratorReducer.keysProfiles[0].fields}, () => {this.clearFields()}, () => {this.insertDefaults()})
			})

			this.setState({profileId: this.props.keyGeneratorReducer.keysProfiles[0].oid})
			this.setState({newKeyType: "SIGNATURE_AND_AGREEMENT"})
			
			this.setCaptchaImg()
		})
	}

	validateAll() {
		let errorCounter = 0;
		for (var i = 0; i < this.state.fields.length; i += 1) {
			const field = this.state.fields[i]
			if (field.optional === false && (field.value === undefined || field.value.length === 0)) {
				field.error = true;
				field.errorMessage = i18next.t("keygen:needToBeFilledField")
				errorCounter +=1
				this.setState({field: field})
			} else {
				field.error = false;
				this.setState({field: field})
			}
		}

		if (errorCounter > 0) {
			this.setState({fieldsIsValid: false})
		} else {
			this.setState({fieldsIsValid: true})
		}

		this.validateCaptcha()
	}

	generateJsonToSend() {
		let data = {}, errorMessage;

		data["NEW_KEY_TYPE"] = this.state.newKeyType
		data["PROFILE_ID"] = this.state.profileId

		for (var i = 0; i < this.state.fields.length; i += 1) {
			if (this.state.fields[i].name === "TELEPHONE_NUMBER") {
				data[String(this.state.fields[i].name)] = (this.state.fields[i].value !== undefined && this.state.fields[i].value.length > 0) ? ("+38" + this.state.selectedCode + this.state.fields[i].value) : ""
			} else {
				if (this.state.fields[i].name === "EXTENDED_KEY_USAGE") {
					if (this.state.fields[i].default !== undefined && this.state.fields[i].default.includes("1.2.804.2.1.1.1.3.9")) {
						data["DIGITAL_STAMP"] = "true"
					}
				} else {
					data[String(this.state.fields[i].name)] = this.state.fields[i].value !== undefined ? this.state.fields[i].value : ""
				}
			}
		}

		this.props.actions.generateNewKeys(this.props.connectionStatus.baseUrl, data)
		.then((response) => {
			if (response.failureCause !== undefined) {
				this.clearFields()
				errorMessage = {
                  message: response.message,
                  techMessage: response.failureCause
                }
                this.props.actions.setErrorData(errorMessage)
                this.props.actions.setShowErrorPopup(true)
				// alert(response.message);
			} else {

				this.clearFields()
				errorMessage = {
                  message: response.message
                }
                this.props.actions.setErrorData(errorMessage)
                this.props.actions.setShowErrorPopup(true)
            }
			// if (response !== null) {
			// 	this.clearFields()
			// 	errorMessage = {
   //                message: response.message
   //              }
   //              this.props.actions.setErrorData(errorMessage)
   //              this.props.actions.setShowErrorPopup(true)

			// 	// alert(response.message);
			// } else {
			// 	errorMessage = {
   //                message: i18next.t("keygen:keyHasNotGenerated")
   //              }
   //              this.props.actions.setErrorData(errorMessage)
   //              this.props.actions.setShowErrorPopup(true)

			// 	// alert(i18next.t("keygen:keyHasNotGenerated"));
			// }
			this.setCaptchaImg()
		})
	}

	validate(e) {
		var _this = this, tempName = ""
		const id = e.target.name;
		const field = this.state.fields[id]
		const re2 = /^[ІіА-яA-z0-9]+$/;
		const re1 = /^[0-9+-?]+$/;
		const re = /^[0-9+-?]+$/;
		const passportRe = /^[a-zA-Z0-9а-яА-Я]+$/;
		const allFields = this.state.fields

		if (field.name === "SURNAME") {
			_this.state.fields.forEach(function (field) {
				if (field.name === "COMMON_NAME") {
					field.error = false
					field.errorMessage = ""
					tempName = ""
					_this.state.fields.forEach(function (field) {
						if (field.name === "GIVEN_NAME" && field.value.length > 0) {
							tempName = " " + field.value
						}
					})

					field.value = e.target.value + tempName
					_this.setState({field: field})
				}
			})
		}

		if (field.name === "GIVEN_NAME") {
			_this.state.fields.forEach(function (field) {
				if (field.name === "COMMON_NAME") {
					field.error = false
					field.errorMessage = ""
					tempName = ""
					_this.state.fields.forEach(function (field) {
						if (field.name === "SURNAME" && field.value.length > 0) {
							tempName = field.value + " "
						}
					})

					field.value = tempName + e.target.value
					_this.setState({field: field})
				}
			})
		}
	    
		if (this.state.fields[id].optional === false && e.target.value.length === 0) {

			if (field.name === "EDRPOU" || field.name === "DRFOU" || field.name === "UNZR") {
				  if (e.target.value === '' || re.test(e.target.value)) {
			        field.value = e.target.value;
			    }
			    if (field.name === "DRFOU" && (e.target.value === '' || re2.test(e.target.value))) {
			        field.value = e.target.value;
			    }
			} else {
				field.value = e.target.value;
			}

			field.errorMessage = i18next.t("keygen:needToBeFilledField")
			field.error = true
			this.setState({field: field})

		} else {
			if (field.name === "EDRPOU" || field.name === "DRFOU" || field.name === "UNZR" || field.name === "TELEPHONE_NUMBER") {
				
				if (e.target.value === '' || re.test(e.target.value)) {
			        
			        if (e.target.value.length === 8 && field.name === "UNZR" && (field.value.lastIndexOf('-') === -1)) {
			        	field.value = e.target.value + "-";
			        } else {
			        	field.value = e.target.value;
			        }
			        let ptr = "pattern" + field.name;
			        if (this[ptr].test(field.value)) {
			        	field.error = false;
			        } else {
			        	field.errorMessage = i18next.t("keygen:valueNotEqualPattern") + i18next.t("keygen:" + ptr)
			        	field.error = true;
			        }

			        if (this.state.fields[id].optional && e.target.value === '') {
			        	field.error = false;
			        }

			  }

			  if (field.name === "DRFOU" && (e.target.value === '' || re2.test(e.target.value))) {
			        field.value = e.target.value;

			        let ptr = "pattern" + field.name;
			        if (this[ptr].test(field.value)) {
			        	field.error = false;
			        } else {
			        	if (field.name === "DRFOU") {
			        			field.errorMessage = i18next.t("keygen:valueNotEqualPatternEnd")
			        			field.error = true;
			        	} else {
			        			field.errorMessage = i18next.t("keygen:valueNotEqualPattern") + i18next.t("keygen:" + ptr)
			        		field.error = true;
			        	}
			        	
			        }

			        if (this.state.fields[id].optional && e.target.value === '') {
			        	field.error = false;
			        }
			  }
			} else {
				
				if (field.name === "PASSPORT") {
					if (e.target.value === '' || passportRe.test(e.target.value)) {
				        field.value = e.target.value;
				        if (field.value.length === 9) {
							field.value = e.target.value;
							field.error = false;
							allFields.forEach(function(field) {
								if (field.name === "UNZR") {
									field.optional = false;
									_this.setState({allFields: allFields})
								}
							})
						} else {
							field.value = e.target.value;
							allFields.forEach(function(field) {
								if (field.name === "UNZR") {
									field.optional = true;
									_this.setState({allFields: allFields})
								}
							})
						}
				    }
				}

				if (field.name === "LOCALITY_NAME") {
					field.value = e.target.value;
					if (field.value.length > 0) {
						field.error = false
					}
					if (field.value === "Киев" || field.value === "Kiev" || field.value === "Kyiv" || field.value === "Київ" || field.value === "Севастополь" || field.value === "Sevastopol" || field.value === "Севастополь") {
						allFields.forEach(function(field) {
							if (field.name === "STATE_OR_PROVINCE_NAME") {
								field.optional = true;
								_this.setState({allFields: allFields})
							}
						})
					} else {
						allFields.forEach(function(field) {
							if (field.name === "STATE_OR_PROVINCE_NAME") {
								field.optional = false;
								_this.setState({allFields: allFields})
							}
						})
					}
				} else {
					if (field.name !== "PASSPORT") {
						field.value = e.target.value
						field.error = false;
					}
				}
			}

			// eslint-disable-next-line
			String.prototype.splice = function(idx, rem, str) {
			    return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
			};

			if (field.name === "TELEPHONE_NUMBER") {
				if (e.target.value === '' || re1.test(e.target.value)) {
					if (e.target.value.length > 2 && (field.value.lastIndexOf('-') === -1)) {
						if (e.target.value.length > 3 && (field.value.lastIndexOf('-') === -1)) {
							field.value = e.target.value.splice(3, 0, "-");
						} else {
							field.value = e.target.value
						}
			        } else {
			        	if (e.target.value.length > 6) {
			        		if (field.value.lastIndexOf('-') !== 6) {
			        			field.value = e.target.value.splice(6, 0, "-");
			        			// field.value = e.target.value + "-";
			        		} else {
			        			field.value = e.target.value
			        		}
		        		} else {
		        			field.value = e.target.value
		        		}
			        }
			        let ptr = "pattern" + field.name;
			        if (this[ptr].test(field.value)) {
			        	field.error = false;
			        } else {
			        	field.errorMessage = i18next.t("keygen:valueNotEqualPattern") + i18next.t("keygen:" + ptr)
			        	field.error = true;
			        }

			        if (this.state.fields[id].optional && e.target.value === '') {
			        	field.error = false;
			        }
			    }
			}
			
			this.setState({field: field})
		}
	}

	validateCaptcha(e) {
		if (e === undefined) {
			if (this.state.captchaValue !== this.state.pattern && this.state.captchaValue.length !== 0 ) {
				this.setState({captchaIsValid: false})
				this.setState({capthcaError: i18next.t("keygen:valueNotEqualCaptcha")})
			} else {
				if (this.state.captchaValue.length === 0) {
					this.setState({captchaIsValid: false})
					this.setState({capthcaError: i18next.t("keygen:needToBeFilledField")})
				} else {
					this.setState({captchaIsValid: true})
					this.setState({capthcaError: ""}, () => {
					    if (this.state.fieldsIsValid) {
					    	this.generateJsonToSend()
					    }
					})
				}
			}
		} else {
			if (e.target.value.length === 0) {
				this.setState({captchaValue: e.target.value})
				this.setState({captchaIsValid: false})
				this.setState({capthcaError: i18next.t("keygen:needToBeFilledField")})
			} else {
				this.setState({captchaValue: e.target.value})
				if (e.target.value !== this.state.pattern) {
					this.setState({captchaIsValid: false})
					this.setState({capthcaError: i18next.t("keygen:valueNotEqualCaptcha")})
				} else {
					this.setState({captchaIsValid: true})
					this.setState({capthcaError: ""})
				}
			}
		}
	}

	setCaptchaImg() {
		var _this = this;
		var text = this.generateRandomText(captchaLength);

		this.setState({pattern: text})

		fetch(this.props.connectionStatus.baseUrl + "/captcha/imageGenerator/" + text, {
	        method: 'GET',
	        headers: {
	          'Content-Type': 'image/png'
	        }
	    }).then(function(response) {
		  return response.blob();
		}).then(function(myBlob) {
		  var objectURL = URL.createObjectURL(myBlob);
		  _this.setState({imgSrc: objectURL});
		});
	}

	insertDefaults() {
		var _this = this, checkStateOrProvince;
		for (var i = 0; i < this.state.fields.length; i += 1) {
			const field = this.state.fields[i]

			if (field.default !== undefined && field.default.length > 0) {
				field.default = field.default.replace(/&quot;/g, '"');
				field.value = field.default
				this.setState({field: field})
			}

			if (field.name === "COUNTRY_NAME") {
				field.value = field.default.toUpperCase()
				this.setState({field: field})
			}

			if (field.name === "DRFOU") {
				field.value = Number
				this.setState({field: field})
			}

			if (field.name === "UNZR") {
				field.value = Number
				this.setState({field: field})
			}

			if (this.props.keyGeneratorReducer.keysProfiles[this.props.keyGeneratorReducer.selectedProfile].id === 3 && field.name === "COMMON_NAME") {
				field.isReadonly = true;
				this.setState({field: field})
			} else {
				if (field.name === "COMMON_NAME") {
					field.isReadonly = false;
					this.setState({field: field})
				}
			}
			
		}

		checkStateOrProvince = function () {
			_this.state.fields.forEach(function(field) {
				if (field.name === "LOCALITY_NAME" && field.value.length > 0) {
					if (field.value === "Киев" || field.value === "Kiev" || field.value === "Kyiv" || field.value === "Київ" || field.value === "Севастополь" || field.value === "Sevastopol" || field.value === "Севастополь") {
						_this.state.fields.forEach(function(field) {
							if (field.name === "STATE_OR_PROVINCE_NAME") {
								field.optional = true;
								_this.setState({field: field})
							}
						})
					} else {
						_this.state.fields.forEach(function(field) {
							if (field.name === "STATE_OR_PROVINCE_NAME") {
								field.optional = false;
								_this.setState({field: field})
							}
						})
					}
				}
			});
		}

		checkStateOrProvince();
		// if (this.props.keyGeneratorReducer.keysProfiles[this.state.selectedProfileId].id === 3) {
		// 	this.insertADData();
		// }
	}

	onDropdownSelected(e) {
		var _this = this;
		let value = e.target.value;
		this.setState({fields: null})
		this.props.actions.setProfile(value)
		this.setState({"showLoginForm": false})
		this.props.baseUrls.keysProfilesIdsForAD.forEach(function (index) {
			if (_this.props.keyGeneratorReducer.keysProfiles[value].id === index) {
				_this.setState({"showLoginForm": true})
				return false;
			}
		})
		// if (this.props.keyGeneratorReducer.keysProfiles[value].id === 3) {
		// 	this.setState({"showLoginForm": true})
		// } else {
		// 	this.setState({"showLoginForm": false})
		// }

		this.setState({selectedProfileId: value}, () => {
			this.setState({fields: this.props.keyGeneratorReducer.keysProfiles[value].fields}, () => {this.clearFields()}, () => {this.insertDefaults()})
		})

		this.setState({profileId: this.props.keyGeneratorReducer.keysProfiles[value].oid})
		this.setState({newKeyType: "SIGNATURE_AND_AGREEMENT"})
		
		this.setCaptchaImg()
	}

	insertADData() {
		if (this.state.adData !== null) {
			for (var i = 0; i < this.state.fields.length; i += 1) {
				const field = this.state.fields[i];
				if (this.state.adData[field.name] !== undefined) {
					if (field.name !== "ORGANIZATIONAL_UNIT_NAME" && field.name !== "TITLE") {
						field.value = this.state.adData[field.name]
						field.isReadonly = true;
						this.setState({field: field})
					}
				}			
			}
		}
	}

	hover() {
	    this.setState({showPassword: false})
	}

	out() {
	    this.setState({showPassword: true})
	}

    clearFields(){
    	var i
		for (i = 0; i < this.state.fields.length; i += 1) {
			const field = this.state.fields[i]
			field.error = false;
			field.value = "";

			if (this.props.keyGeneratorReducer.keysProfiles[this.props.keyGeneratorReducer.selectedProfile].id === 3 && field.name === "COMMON_NAME") {
				field.isReadonly = true;
			}
			// if (field.name === "PASSPORT") {
			// 	field.optional = false
			// }
			this.setState({field: field})
		}

		this.setState({"adDataRecived": true})

		this.setState({"showADRecivedDataStatus": false})

		this.setState({"loginInput": ""})
	    this.setState({"pwdInput": ""})

		this.insertDefaults()
		this.setState({capthcaError: ""})
		this.setState({captchaValue: ""})
    }

	render() {
		const loginEnabled = this.state.loginInput.length > 0 && this.state.pwdInput.length > 0
      			? true : false
		return (
			<div>
				<div className="offset-md-2">
					<div style={{paddingTop: "10px"}}>
					<label style={{marginBottom: "10px"}}>{i18next.t("keygen:keysGenerationProfile")}</label>
						{
                            this.props.keyGeneratorReducer.keysProfiles ? <select type="select" className="form-control col-md-10" style={{textAlign: "center", textAlignLast: "center", marginBottom: "15px"}} onChange={this.onDropdownSelected}>
                                    {
                                      this.props.keyGeneratorReducer.keysProfiles.map((child, index) =>
                                        <option key={index} value={index}>{child.caption}</option>)
                                    }          
                                </select> : null
                        }
                        {
							this.state.showLoginForm ? <div style={{paddingBottom: "10px"}}>
								<div className="col-md-10" style={{border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem", "paddingTop": "10px"}}>
									{
										!this.state.showADRecivedDataStatus
										? <div>
											<div className="form-group row">
					                          	<label className="col-3 col-form-label">{i18next.t("userTitle")}</label>
					                            <div className="col-9">
					                              <div className="input-group">
					                                <input type="text" className="form-control" style={{"backgroundColor": "white"}} onChange={this.handleLoginChange} value={this.state.loginInput} />
					                              </div>
					                            </div>
					                        </div>
					                        <div className="form-group row">
					                          	<label className="col-3 col-form-label">{i18next.t("password")}</label>
					                            <div className="col-9">
					                              	<div className="input-group">
					                                  	<input className="form-control" type={this.state.showPassword ? "password" : "input"} onChange={this.handlePasswordChange} value={this.state.pwdInput} />
					                                      	<div className="input-group-append">
					                                          	<div className="input-group-text" onMouseOver={this.hover.bind(this)} onMouseOut={this.out.bind(this)}><i className="fa fa-eye"></i></div>
					                                      	</div>
					                                	</div>
					                              	</div>
					                        </div>
					                        <button disabled={!loginEnabled} className="btn btn-default btn-block" style={{marginBottom: "10px"}} onClick={this.getADData.bind(this)}>{i18next.t("getADDataTitle")}</button>
										</div>
										: <div className="form-group row">
											{
												this.state.adDataRecived
												? <div className="col-md-12 col-sm-12 col-xs-12 form-group row"><div className="col-md-10 col-sm-10 col-xs-10" style={{"textAlign": "left"}}>{i18next.t("keygen:adDataRecivedSuccessTitle")}</div><div className="col-md-2 col-sm-2 col-xs-2" style={{"textAlign": "right"}}><i className="fa fa-check-circle"></i></div></div>
												: <div className="col-md-12 col-sm-12 col-xs-12 form-group row"><div className="col-md-10 col-sm-10 col-xs-10" style={{"textAlign": "left"}}>{i18next.t("keygen:adDataRecivedFailedTitle")}</div><div className="col-md-2 col-sm-2 col-xs-2" style={{"textAlign": "right"}}><i className="fa fa-exclamation-triangle"></i></div></div>
											}
										</div>
									}
								</div>
							</div> : null
						}
                        {
							this.state.fields
							? 
							this.state.fields.map((child, index) =>
							<div>
								{
									!child.optional
									? <div className="form-group row" key={index}>
										<label className="col-md-3 text-md-right col-form-label">
											{
												i18next.t("keygen:" + child.name) + ((child.optional === true) ? ":" : "*:")
											}
										</label>

											{
												this.state.fields[index].name !== "TELEPHONE_NUMBER"
												? <div className="col-md-7">
													<input className={this.state.fields[index].error ? "form-control is-invalid" : "form-control"}
														type="text" readOnly={this.state.fields[index].isReadonly} required={this.state.fields[index].error ? true : false} value={this.state.fields[index].value}
														name={index} onInput={this.validate} onBlur={this.validate} maxlength={this.state.fields[index].name === "UNZR"
														? "14"
														: this.state.fields[index].name === "PASSPORT" ? "9"
														: this.state.fields[index].name === "DRFOU" ? "10"
														: this.state.fields[index].name === "EDRPOU" ? "8" : ""} placeholder={this.state.fields[index].name === "UNZR" ? "00000000-00000" : ""}></input>
														{
															this.state.fields[index].error ?
															<span style={{color: "red"}}>{this.state.fields[index].errorMessage}</span> : null
														}
												</div>
												: <div className="col-md-7">
													
													<div className="input-group">
													<label style={{"marginRight": "10px"}} className="col-md-1 col-form-label">+38</label>&nbsp;
														<select className="col-md-2 form-control" onChange={this.handleCodeChange}>
															{
						                                      this.state.operatorsCodes.map((code, index) =>
						                                        <option key={index} value={code}>{code}</option>)
						                                    }
														</select>&nbsp;
														<input className={this.state.fields[index].error ? "col-md-9 form-control is-invalid" : "col-md-9 form-control"}
															type="text" required={this.state.fields[index].error ? true : false} value={this.state.fields[index].value}
															name={index} onInput={this.validate} maxlength="9" onBlur={this.validate}></input>
													</div>
														{
															this.state.fields[index].error ?
															<span style={{color: "red"}}>{this.state.fields[index].errorMessage}</span> : null
														}
													
												</div>
											}
									</div>
									: null
								}
							</div>) : null
						}
						{
							this.state.fields
							? <div className="form-group row">
								<label className="col-md-3 text-md-right col-form-label">
								</label>
								<div className="col-md-7">
									<button className="btn btn-default form-control" style={{marginBottom: "10px"}} onClick={this.setShowNotRequiredFields.bind(this)}>
										{
			                              	this.state.showNotRequiredFields ? <span>{i18next.t("keygen:notRequiredFieldsTitle")}&nbsp;<i className="fa fa-caret-up"></i></span>
			                                : <span>{i18next.t("keygen:notRequiredFieldsTitle")}&nbsp;<i className="fa fa-caret-down"></i></span>
			                            }
									</button>
								</div>
							</div>
							: null

						}
					
					</div>
					
					{
						this.state.fields
						? 
						this.state.fields.map((child, index) =>
							<div>
								{
									child.optional && this.state.showNotRequiredFields
									? <div className="form-group row" key={index}>
										<label className="col-md-3 text-md-right col-form-label">
											{
												i18next.t("keygen:" + child.name) + ((child.optional === true) ? ":" : "*:")
											}
										</label>

											{
												this.state.fields[index].name !== "TELEPHONE_NUMBER"
												? <div className="col-md-7">
													<input className={this.state.fields[index].error ? "form-control is-invalid" : "form-control"}
														type="text" readOnly={this.state.fields[index].isReadonly} required={this.state.fields[index].error ? true : false} value={this.state.fields[index].value}
														name={index} onInput={this.validate} onBlur={this.validate} maxlength={this.state.fields[index].name === "UNZR"
														? "14"
														: this.state.fields[index].name === "PASSPORT" ? "9"
														: this.state.fields[index].name === "DRFOU" ? "10" : ""} placeholder={this.state.fields[index].name === "UNZR" ? "00000000-00000" : ""}></input>
														{
															this.state.fields[index].error ?
															<span style={{color: "red"}}>{this.state.fields[index].errorMessage}</span> : null
														}
												</div>
												: <div className="col-md-7">
													
													<div className="input-group">
													<label style={{"marginRight": "10px"}} className="col-md-1 col-form-label">+38</label>&nbsp;
														<select className="col-md-2 form-control" onChange={this.handleCodeChange}>
															{
						                                      this.state.operatorsCodes.map((code, index) =>
						                                        <option key={index} value={code}>{code}</option>)
						                                    }
														</select>&nbsp;
														<input className={this.state.fields[index].error ? "col-md-9 form-control is-invalid" : "col-md-9 form-control"}
															type="text" required={this.state.fields[index].error ? true : false} value={this.state.fields[index].value}
															name={index} onInput={this.validate} maxlength="9" onBlur={this.validate}></input>
													</div>
														{
															this.state.fields[index].error ?
															<span style={{color: "red"}}>{this.state.fields[index].errorMessage}</span> : null
														}
													
												</div>
											}
									</div>
									: null

								}
							</div>)
						: null
					}
					{
						this.state.imgSrc
						? 
						<div>
							<div className="form-group row">
								<label className="col-md-3 text-md-right col-form-label">
									CAPTCHA*:
								</label>
									<div className="col-md-2">
										<img src={this.state.imgSrc} style={{width: "100px", height: "35px"}} alt="Captcha" />
									</div>
									<input className={this.state.capthcaError.length > 0 ? "form-control col-md-2 is-invalid" : "form-control col-md-2"} type="text" onInput={this.validateCaptcha} onBlur={this.validateCaptcha} value={this.state.captchaValue}></input>
									<div className="col-md-2">
							            <img src={arrows} width="32" height="32" alt="arrows" onClick={this.setCaptchaImg.bind(this)} />
							        </div>
							</div>
								<div className="offset-md-3 col-6" style={{marginBottom:"10px"}}>
									{
										this.state.capthcaError.length > 0 ?
										<span style={{color: "red"}}>{this.state.capthcaError}</span> : null
									}
								</div>
							<button className="btn btn-default offset-md-3" style={{marginBottom: "15px"}} onClick={this.validateAll.bind(this)}>{i18next.t("keygen:generateKeys")}</button>
							<button className="btn btn-default offset-md-3" style={{marginLeft: "7px", marginBottom: "15px"}} onClick={this.clearFields}>{i18next.t("keygen:clearFields")}</button>
						</div> : null
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        connectionStatus: state.connectionStatusReucer,
        locales: state.i18n,
        localesReducer: state.localesReducer,
        keyGeneratorReducer: state.keyGeneratorReducer,
        baseUrls: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	connectionStatus,
    	getKeysProfiles,
    	setKeysProfiles,
    	setProfile,
    	generateNewKeys,
    	getADUserInfo, setErrorData, setShowErrorPopup
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyGenerator);